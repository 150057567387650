import React from 'react';
import CircularProgressOverlay from 'components/CircularProgressOverlay';
import MainLayout from 'layouts/mainLayout';
import SignupPage from 'components/Pages/callcenter/signup';

const LoginTemplate = () => (
  <MainLayout>
    <CircularProgressOverlay>
      <SignupPage />
    </CircularProgressOverlay>
  </MainLayout>
);

export default LoginTemplate;
